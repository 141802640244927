import React from 'react';

import { TEASER_FOCUS_INDICATOR_SIZE, Teaser, TeaserProps } from './Teaser';
import { FirstParameter } from '../../types';
import { isTizen5 } from '../../utils/userAgent';
import { RATIO } from '../../utils/window';

export const RANKING_TEASER_WIDTH = 335;
export const RANKING_TEASER_HEIGHT = Math.ceil(RANKING_TEASER_WIDTH * RATIO);
const PADDING = 4;

export const RANKING_SPACING = (RANKING_TEASER_HEIGHT - PADDING * 2) / 1.5 / 3;

export type OnRankingFocusBlurParams = FirstParameter<TeaserProps['onFocus']>;

export type RankingTeaserProps = {
  index: number;
  imageUrl: string;
  $width?: number;
  $height?: number;
  opacity?: number;
  style?: React.CSSProperties;
  onFocus?: (params: OnRankingFocusBlurParams) => void;
};

export const RankingTeaser: React.FC<RankingTeaserProps> = (props) => {
  const { index, imageUrl, style, $width = RANKING_TEASER_WIDTH, $height = RANKING_TEASER_HEIGHT, onFocus, ...otherProps } = props;

  const dimensions = { $width, $height };

  const contentHeight = dimensions.$height - PADDING * 2;
  const numberSize = contentHeight / 1.5;
  const offsetVertical = numberSize / 4;
  const offsetHorizontal = numberSize / 3;
  const containerStyle = { ...style, ...dimensions };
  const numberStyle = { fontSize: numberSize, lineHeight: `${numberSize}px` };
  const teaserContainerStyle = { marginTop: offsetVertical, marginLeft: offsetHorizontal };

  const handleOnFocus: TeaserProps['onFocus'] = (params) => {
    onFocus && onFocus(params);
  };

  return (
    <div style={containerStyle}>
      <div className='absolute top-0 bg-transparent' id='ranking-teaser-number-container' style={numberStyle}>
        <div
          className='bg-gradient-to-tr from-[rgba(175,0,29,1)] to-[rgba(115,8,56,1)] font-Display-Bold'
          id='ranking-teaser-number'
          style={{
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            WebkitTextStroke: '3px #e31f2b',
            filter: isTizen5() ? 'none' : 'drop-shadow(0 0 8px rgba(227, 31, 43, 0.6))',
          }}
        >
          {index + 1}
        </div>
      </div>
      <div style={teaserContainerStyle}>
        <Teaser
          index={index}
          $width={RANKING_TEASER_WIDTH - TEASER_FOCUS_INDICATOR_SIZE}
          $height={RANKING_TEASER_HEIGHT - TEASER_FOCUS_INDICATOR_SIZE}
          imageUrl={imageUrl}
          onFocus={handleOnFocus}
          ranking
          {...otherProps}
        />
      </div>
    </div>
  );
};
