import { ForwardedRef, RefObject } from 'react';

import { isProductionEnvironment } from '@srgssr/rio-common';
import { Maybe } from '@srgssr/rio-shared';

export class LocalError extends Error {}

/* eslint-disable no-param-reassign */
export const setComponentRefs =
  <T>(ref: RefObject<T | null>, forwardedRef: ForwardedRef<T>) =>
  (el: T) => {
    ref.current = el;
    if (typeof forwardedRef === 'function') forwardedRef(el);
    else if (forwardedRef) forwardedRef.current = el;
  };
/* eslint-enable no-param-reassign */

export const hasIndex = <T>(item: T | undefined): item is Extract<T, { index: number }> =>
  (item as { index: number } | undefined)?.index != null;

export const getAcronym = (str: string) =>
  str
    .split(' ')
    .map((item) => item[0])
    .join('')
    .replace(/[^A-Za-z0-9]/g, '');

/**
 * Used to force only two letters from a name acronym (removing middlenames and longer names)
 * @param str name
 */
export const getTwoLetterNameAcronym = (str: string) => {
  const acronym = getAcronym(str);
  if (acronym && acronym.length > 2) {
    return acronym
      .split('')
      .filter((_, i) => i % 2 === 0)
      .slice(0, 2)
      .join('');
  }
  return acronym;
};

/**
 * Check if string starts with http or https
 * @param url url
 * @returns boolean
 */
export const isHttpUrl = (url: string) => url.startsWith('http://') || url.startsWith('https://');

const DURATION_EMPTY_STEP = '00:';
/**
 * Converts seconds to duration in 00:00:00 format
 * @param seconds string
 * @returns
 */
export const secondsToDuration = (seconds: number) => {
  const date = new Date(0);
  date.setSeconds(seconds);
  let duration = date.toISOString().substring(11, 19);
  while (duration.startsWith(DURATION_EMPTY_STEP) && duration.length > 5) {
    duration = duration.slice(DURATION_EMPTY_STEP.length);
  }
  return duration;
};

/**
 * Replace last space by non breaking space if string ends by ! or ?
 * @param str string
 * @returns string
 */
export const replaceNonBreakingSpaces = (str: Maybe<string>) => str?.replace(/\s*(?=!|\?)\s*/g, '\u00A0');

/**
 * Replaces line breaks in string by spaces
 * @param str string
 * @returns string
 */
export const replaceLineBreaksBySpaces = (str: string) => str.replace(/\r?\n|\r/g, ' ');

/**
 * Promise thats resolves after a specified duration
 * @param duration number
 * @returns
 */
export const wait = (duration = 0) =>
  new Promise((resolve) => {
    setTimeout(resolve, duration);
  });

export const debugAlert = (message: string) => {
  if (!isProductionEnvironment()) {
    // eslint-disable-next-line no-alert
    alert(message);
  }
};
